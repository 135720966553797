@charset "utf-8";

.c-page_header {
  position: relative;
  margin: 0 0 30px 0;

  .c-page_header__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  .c-page_header__main_title {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }

  .c-page_header__sub_title {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .c-page_header__image {
    width: 100%;
  }
}
