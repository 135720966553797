@charset "utf-8";

.c-accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin: 0 0 100px 0;
  word-wrap: break-word;
}

.c-accordion__title {
  // margin: 0 0 50px 0;
  margin-bottom: 50px;
  // text-align: center;
}

.c-accordion__post_button {
  color: $c-accordion__post_title__color;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $c-accordion__post_title__color;
    text-decoration: none;
  }
}

.c-accordion__post_title__wrap {
  align-items: center;
  margin: 0 0 -1px 0;
  padding: 15px;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;

  &:focus,
  &:hover {
    background-color: $c-accordion__post_title__color--hover;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 0 10px 0;
  }
}

.c-accordion__post_date {
  margin: 0;
  font-size: 1rem;
  white-space: nowrap;
}

.c-accordion__post_title {
  margin: 0;
  font-size: 1.5rem;
}

.c-accordion__post_text {
  margin: 0 0 30px 0;
  padding: 30px;
  text-align: justify;
  text-justify: inter-ideograph;
  background-color: hsl(0, 0%, 98%);
  border-top: solid 1px hsl(0, 0%, 90%);
  border-bottom: solid 1px hsl(0, 0%, 90%);

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.c-accordion__contents_title {
  font-size: 1.25rem;
  margin: 0 0 1.25rem 0;
}

.c-accordion__branch_line {
  margin: 3rem 0 2rem 0;
  border: dashed 1px hsl(0, 0%, 90%);
}
