@charset "utf-8";

.c-breadcrumb {
  padding-left: 0;
  margin: 0 0 50px 0;
}

.c-breadcrumb__item {
  display: inline;
  list-style: none;

  &::after {
    /* > を表示 */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f105';
    font-size: 1rem;
    padding: 0 10px 0 15px;
  }

  &:last-child::after {
    content: '';
  }

  &:first-child .c-breadcrumb__link::before {
    /* 家アイコン */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f015';
    font-size: 1rem;
    padding: 0 5px 0 0;
  }
}
