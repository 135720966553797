@charset "utf-8";

.c-sitemap {
  padding-left: 15px;
  margin: 0 0 50px 0;

  @include media-breakpoint-only(xs) {
    margin: 0 0 20px 0;
  }
}

.c-sitemap__item {
  list-style: none;

  &:first-child {
    font-weight: bold;
  }

  &:first-child::before {
    /* >> アイコン */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f101';
    font-size: 1rem;
    padding: 0 7px 0 0;
    color: #005874;
  }

  &::before {
    /* > アイコン */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f105';
    font-size: 1rem;
    padding: 0 10px 0 3px;
  }
}

.c-sitemap__link {
  color: rgba(0, 0, 0, 0.5);

  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}
