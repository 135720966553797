@charset "utf-8";

.c-section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.c-section--iso27001 {
}

.c-section__title {
  margin: 0 0 50px 0;

  @include media-breakpoint-only(xs) {
    font-size: 1.75rem;
    text-align: center;
  }
}

.c-section__title--iso27001 {
  margin-bottom: 50px;

  @include media-breakpoint-only(xs) {
    font-size: 1.75rem;
    text-align: center;
  }
}

.c-section__sub_title {
  margin: 0 0 2rem 0;
  font-size: 1.5rem;
  text-align: justify;
  text-justify: inter-ideograph;
}

.c-section__title--highlighter {
  background: linear-gradient(transparent 80%, hsl(0, 0%, 100%) 80%);
}

.c-section__emphasis {
  margin: 0 10px 0 10px;
  color: #EB6B42;
  font-weight: normal;
  white-space: nowrap;
}

.c-section__text {
  margin: 0 0 2rem 0;
  text-align: justify;
  text-justify: inter-ideograph;
}
