@charset "utf-8";

.c-main_copy {
  text-align: center;
  margin: 0 0 50px 0;

  .c-main_copy__text {
    margin: 0;
    font-size: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    .c-main_copy__kenten {
      text-emphasis: dot filled rgb(255, 190, 0);
    }

    .c-main_copy__highlighter {
      background: linear-gradient(transparent 80%, rgb(255, 245, 214) 80%);
    }
  }
}
