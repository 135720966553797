@charset "utf-8";

.c-application_inquiries {
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
}

.c-application_inquiries--type2 {
  padding: 1rem;
  background-color: $l-section_wrap--with_bg_color__color;
  border-radius: 10px;
}

.c-application_inquiries__title {
  margin: 0 0 30px 0;
  font-size: 1.5rem;
  text-align: center;
}

.c-application_inquiries__phone {
  margin: 0;
  color: #005874;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }

  .fa-phone {
    margin: 0 0.5rem 0 0;
    font-size: 1.75rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
}

.c-application_inquiries__text {
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.c-application_inquiries__sub_title {
  font-size: 1.25rem;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}
