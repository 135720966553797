@charset "utf-8";

.btn-contact--header {
  color: #FFF;
  background-color: hsl(193, 70%, 36%);
  border-color: hsl(193, 70%, 36%);

  &:hover {
    color: #FFF;
    background-color: hsl(193, 70%, 30%);
    border-color: hsl(193, 70%, 30%);
  }
}

.btn-contact {
  color: #FFF;
  background-color: hsl(40, 100%, 50%);
  border-color: hsl(40, 100%, 47%);
  line-height: 2.5;

  &:active,
  &:hover {
    color: #FFF;
    background-color: hsl(40, 100%, 45%);
    border-color: hsl(40, 100%, 42%);
  }
}

.btn-plink {
  color: #FFF;
  background-color: hsl(194, 100%, 23%);
  border-color: hsl(194, 100%, 23%);

  &:active,
  &:hover {
    color: #FFF;
    background-color: hsl(194, 100%, 18%);
    border-color: hsl(194, 100%, 18%);
  }
}

.btn-plink--ol {
  color: hsl(194, 100%, 23%);
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 75%);

  &:active,
  &:hover {
    color: hsl(194, 100%, 23%);
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 70%);
  }
}

.btn-mailform {
  width: 300px;
  color: #FFF;
  background-color: hsl(40, 100%, 50%);
  border-color: hsl(40, 100%, 47%);

  &:active,
  &:hover {
    color: #FFF;
    background-color: hsl(40, 100%, 45%);
    border-color: hsl(40, 100%, 42%);
  }

  @include media-breakpoint-only(xs) {
    width: 65%;
  }
}
