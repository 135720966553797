@charset "utf-8";

.c-salutation {
  margin: 0 0 6.25rem 0;
}

.c-salutation__symbol_mark {
  text-align: center;
}

.c-salutation__text_wrap {
}

.c-salutation__text {
  text-indent: 1rem;

  & > strong::after {
    content: '］';
    padding: 0 0 0 0.2rem;
  }

  & > strong::before {
    content: '［';
    padding: 0 0.2rem 0;
  }
}
