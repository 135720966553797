@charset "utf-8";

.form-group {
  border-top: solid 1px #DDD;
  border-bottom: solid 1px #DDD;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -1px;
  margin-left: 0;
  margin-right: 0;
}

input.disabled {
  pointer-events: none;
}
