@charset "utf-8";

.c-header__nav {
  width: 100%;
  margin: 10px 0 0 0;
}

.c-header__nav_menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0 10px 0;
  padding: 0;
}

.c-nav__item {
  text-align: center;
  list-style: none;
}

.c-nav__link {
  display: block;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-decoration: none;
  border-bottom: solid 1px hsla(0, 0%, 100%, 0);

  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.7);
    border-bottom: solid 1px $c-nav__link__active__color;
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    padding: 0.75rem 0 0.75rem 0;
    border-bottom: solid 1px hsl(0, 0%, 90%);

    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.7);
      background-color: hsl(0, 0%, 90%);
      border-bottom: solid 1px $c-nav__link__active__color;
    }
  }
}

.c-nav__item.active {
  .c-nav__link {
    color: rgba(0, 0, 0, 0.9);
    border-bottom: solid 3px $c-nav__link__active__color;
  }

  @include media-breakpoint-down(sm) {
    .c-nav__link {
      border-bottom: sold 3px $c-nav__link__active__color;
    }
  }
}

@include media-breakpoint-down(sm) {
  .c-header__nav_menu {
    display: block;

    .c-nav__link {
      margin: 1px;
      background-color: hsl(0, 0%, 95%);
    }
  }
}
