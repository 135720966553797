@charset "utf-8";

.c-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  height: 100%;
}

.c-card--type2 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  // height: 100%;
}

.c-card__image {
  width: 100%;
  // height: 100%;
}

.c-card__body {
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

.c-card__body--type2 {
  flex: 1 1 auto;
  padding: $card-spacer-x 0 $card-spacer-x 0;
}

.c-card__title {
  margin-bottom: $card-spacer-y;
  text-align: center;
}

.c-card__title--type2 {
  margin: 0 0 2rem 0;
  text-align: center;
}

.c-card__text:last-child {
  margin-bottom: 0;
  text-align: justify;
  text-justify: inter-ideograph;
}

.c-card__button {
}
