@charset "utf-8";

.c-header {
  padding: 40px 30px 10px 30px;
  border-bottom: solid 1px rgb(190, 190, 190);
  background-image: url('/asset/image/header__top_band.png');
  background-repeat: repeat-x;

  @include media-breakpoint-down(sm) {
    padding: 20px 15px 10px 15px;
  }

  .c-header__container {
    align-items: center;
    width: 100%;
    // margin: 0 0 0 0;

    .c-header__logo {
      @include media-breakpoint-only(xs) {
        width: 80%;
      }

      .c-header__logo_link {
        display: block;
        text-decoration: none;
        color: #000;

        &:active,
        &:hover {
          opacity: 0.5;
        }

        .c-header__logo_tagline {
          margin: 0;
          line-height: 1.3rem;
        }

        .c-header__logotype {
          margin: 0;
          font-size: 0;
        }
      }
    }

    .c-header__contact_buton {
      font-size: 1rem;
      padding: 0.75rem;

      .fa-envelope {
        margin: 0 0.5rem 0 0;
      }
    }

    .c-header__phone {
      margin: 0 20px 0 0;

      .c-header__phone_num {
        font-size: 1.75rem;
        color: #005874;
        font-weight: bold;
        margin: 0;
        line-height: 1.75rem;

        .fa-phone {
          font-size: 1.5rem;
          margin: 0 0.5rem 0 0;
        }
      }

      .c-header__reception_time {
        font-size: 0.75rem;
        margin: 0;
      }

      .c-header__nav_toggle {
        border: solid 1px #ddd;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .c-header--slim {
    padding: 20px 30px 10px 30px;
  }
}
