@charset "utf-8";

.c-isms_policy {
  padding: 0 6rem 0 6rem;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.c-isms_policy__title {
  margin-bottom: 2rem;
}

.c-isms_policy__text {
  margin-bottom: 3rem;
  text-align: justify;
  text-justify: inter-ideograph;
}

.c-isms_policy__list {
  padding-inline-start: 1.5rem;
  margin-bottom: 3rem;
}

.c-isms_policy__list_item {
  margin-bottom: 1rem;
  text-align: justify;
  text-justify: inter-ideograph;
}
