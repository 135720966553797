@charset "utf-8";

.c-footer {
  > .c-footer__logo {
    margin: 0 0 1.5rem 0;
  }

  .c-footer__text {
    margin: 0 0 0.5rem 0;

    @include media-breakpoint-down(sm) {
      margin: 2rem 0 0.5rem 0;
    }
  }

  .c-footer__text--address {
    min-height: 7rem;

    @include media-breakpoint-down(sm) {
      min-height: 0;
    }
  }

  .c-footer__map {
    width: 100%;
    height: 250px;
    border: solid 1px #DDD;
  }
}

.c-footer__copyright_wrap {
  background-color: #005874;
  color: white;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-footer__copyright {
  margin: 0;
}

#page_top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));
  z-index: 1000;
  opacity: 0.9;
  display: none;

  &:active,
  &:hover {
    opacity: 1;
    transition: all 0.3s;
  }
}

.nav-link {
  &:focus,
  &:hover {
    border-bottom: solid 1px $c-nav__link__active__color;
  }
}

.nav-item.active {
  .nav-link {
    border-bottom: solid 3px $c-nav__link__active__color;
  }
}

.c-footer-contact__phone_num {
  font-size: 1.75rem;
  color: #005874;
  font-weight: bold;
  margin: 0;
  line-height: 1.75rem;
}

.c-footer-contact__reception-time {
  font-size: 0.75rem;
  margin: 0;
}

.c-footer-contact__contact-buton {
  font-size: 1rem;
  padding: 0.75rem;
}
