@charset "utf-8";

.c-privacy-policy {
  height: 300px;
  overflow: scroll;
  border: solid 1px #000;
  padding: 20px;

  @include media-breakpoint-only(xs) {
    padding: 5px;
  }
}
