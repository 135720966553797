@charset "utf-8";

.c-profile__title {
  margin: 0 0 2rem 0;
}

.c-info__list {
  margin: 0 0 100px 0;
}

.c-info__item {
  display: flex;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
  margin: -1px 0 0 0;
  padding: 0.5rem 0 0.5rem 0;

  &:nth-child(even) {
    background: hsl(0, 0%, 95%);
  }

  &:nth-child(odd) {
    background: white;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}

.c-info__header {
  width: 10rem;
  text-align: right;
  padding: 0 1rem 0 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
    text-align: left;
    padding: 0 0 0.5rem 0;
  }
}

.c-info__contents {
  margin: 0;
}
