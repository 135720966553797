@charset "utf-8";

.c-main_image {
  position: relative;
  overflow: hidden;
  max-height: 615px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 50px auto;

  .c-main_image__image {
    width: 100%;
  }

  .c-main_image__text {
    display: inline;
    position: absolute;
    // left: 10%;
    top: 50%;
    left: 20%;
    transform: translate(-20%, -50%);
    color: white;
    font-size: 0.75rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    margin: 0;
  }

  @include media-breakpoint-only(xs) {
    .c-main_image__text {
      font-size: 0.85rem;
    }
  }

  @include media-breakpoint-only(sm) {
    .c-main_image__text {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-up(md) {
    .c-main_image__text {
      font-size: 1.5rem;
    }
  }
}
